<template>
    <b-card>
        <template v-slot:header>
            <h5>Selisih Barang</h5>
        </template>
        <b-table bordered hover show-empty :busy="isLoading" :items="daftarSelisih" :fields="fields" responsive
            class="mb-3">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading data...</strong>
                </div>
            </template>
            <template v-slot:cell(action)="row">
                <b-button @click="handleKelolaSelisihBarang(row.item)" size="sm" variant="pgiBtn">Kelola Selisih</b-button>
            </template>
            <template v-slot:cell(total_dana)="row">
                <div class="d-flex justify-content-between">
                    <div>Rp.</div>
                    <span>{{ row.item.total_dana }}</span>
                </div>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import axiosClient from '../../../config/api'

export default {
    data() {
        return {
            isLoading: false,
            daftarSelisih: [],
            fields: [
                { key: "no_invoice", label: "No. Invoice" },
                { key: "tanggal", label: "Tgl. Invoice" },
                { key: "total_item", label: "Total Selisih" },
                { key: "total_dana", label: "Total Dana Selisih" },
                { key: "action", label: "Aksi" },
            ],
        }
    },
    methods: {
        async getSelisihBarang() {
            try {
                this.isLoading = true
                const response = await axiosClient({
                    url: 'transaksi/refund/list-refund',
                    method: 'GET',
                    params: {
                        order_by: 'DESC'
                    }
                })
                const datas = response.data.data
                this.daftarSelisih = datas.map(item => {
                    return {
                        total_dana_rupiah: this.$helper.rupiah(parseInt(item.total_dana)),
                        ...item
                    }
                })
            } catch (error) {
                console.log(error)
            }
            finally {
                this.isLoading = false
            }
        },
        handleKelolaSelisihBarang(item) {
            console.log(item)
            this.$router.push(`/selisih-barang/${item.id}/manage`)
        }
    },
    mounted() {
        this.getSelisihBarang()
    }
}
</script>